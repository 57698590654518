export default {
  heading: 'Lora, sans-serif',
  subheading: 'Lobster',
  body: 'Futura, sans-serif',
  body2: 'Comfortaa, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Cabin Sketch, cursive',
  googleFonts: [
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Comfortaa',
    'Lora:100,200,300,400,500,600,700,800,900',
    'Lobster',
  ],
  customFamilies: ['Amelie', 'Corinthian Light', 'Futura'],
  customUrls: [
    'https://www.gonation.biz/fonts/amelie/amelie.css',
    'https://www.gonation.biz/fonts/corinthian-light/corinthian-light.css',
    'https://www.gonation.biz/fonts/futura/futura.css',
  ],
};
