export default {
  text: '#202020',
  text2: '#a7a57a',
  primary: '#cce7e3',
  secondary: '#444444',
  background: 'white',
  backgroundPrimary: '#48636a',
  backgroundSecondary: '#603d34',
  backgroundTertiary: '#768e74',
  light: '#FFF',
  dark: '#020303',
};
